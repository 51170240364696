<template>
  <div>
    <v-menu transition="slide-x-transition" bottom right>
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-bind="attrs" v-on="on">
          mdi-dots-horizontal
        </v-icon>
      </template>
      <v-list>
        <v-list-item
          @click="crcProfile"
          class="d-flex align-center item-dropdown-user"
        >
          <v-list-item-title>
            <v-icon class="item-dropdown-icon">mdi-store</v-icon
            ><span class="pl-3">Ver perfil</span>
          </v-list-item-title>
        </v-list-item>

        <v-list-item
          v-if="userCanUpdateSituation"
          @click="situationState.dialog = true"
          class="d-flex align-center item-dropdown-user"
        >
          <v-list-item-title>
            <v-icon class="item-dropdown-icon">{{
              situationState.mode.icon
            }}</v-icon
            ><span class="pl-2">{{ situationState.mode.label }}</span>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <change-crc-situation-dialog
      :crc="crc"
      :state="situationState"
      @success="updateData"
    />
  </div>
</template>
<script>
  import ChangeCrcSituationDialog from "@/modules/crc/views/components/ChangeCrcSituationDialog";

  import { mapActions } from "vuex";

  export default {
    props: {
      crc: {
        required: true,
        type: Object
      },
      userCanUpdateSituation: {
        required: true,
        type: Boolean
      }
    },
    components: {
      ChangeCrcSituationDialog
    },
    data: () => ({
      situationState: {
        dialog: false,
        canChangeSituation: false,
        mode: {
          icon: null,
          label: ""
        }
      }
    }),

    methods: {
      crcProfile() {
        // review this
        this.$router.push({
          name: "ViewCRC",
          params: { id: this.crc.id }
        });
      },
      updateData() {
        this.$emit("updateData");
      }
    }
  };
</script>
<style lang="scss" scoped>
  @import "@/assets/stylesheets/styleguide";

  .item-dropdown-user:hover {
    background-color: $white-1;

    .item-dropdown-icon {
      color: $primary-color-2;
    }
  }
</style>
