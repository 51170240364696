<template>
  <data-not-found
    :title="title"
    :imgSrc="require('@/assets/crc.svg')"
    :btnAction="() => $router.push({ name: actionRoute })"
    :btnLabel="actionLabel"
    :canSeeAction="canSeeAction"
  />
</template>

<script>
  import DataNotFound from "@/modules/core/views/components/DataNotFound";

  export default {
    name: "CRCNotFound",

    props: {
      title: {
        type: null,
        required: true
      },

      actionRoute: {
        type: String,
        required: false,
        default: () => "RegisterCrcs"
      },

      actionLabel: {
        type: String,
        required: false,
        default: () => "Cadastrar o primeiro CRC"
      },

      canSeeAction: {
        type: Boolean,
        required: false,
        default: () => false
      }
    },

    components: {
      DataNotFound
    }
  };
</script>

<style></style>
