<template>
  <v-dialog v-model="state.dialog" scrollable :persistent="true" width="488">
    <v-card class="modal">
      <v-card-title class="modal-title">{{
        state.mode.situationModalTitle
      }}</v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <div class="pt-5">
          <p>Tem certeza que deseja realizar esta ação?</p>
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="d-flex justify-space-between">
        <v-btn
          class="cancel-button white--text"
          color="error"
          @click="state.dialog = false"
          >Cancelar</v-btn
        >
        <v-btn
          type="button"
          @click="actionChangeSituation"
          text
          class="next-step-button error--text"
          >Confirmar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  import { mapActions } from "vuex";
  import { CrcSituation } from "../../enum/crcSituation.enum";

  export default {
    props: {
      crc: {
        required: true,
        type: Object
      },
      state: {
        required: true,
        type: Object
      }
    },
    data: () => ({
      situationModes: {
        [CrcSituation.ACTIVATED]: {
          label: "Desativar",
          icon: "mdi-block-helper",
          situationModalTitle: "Você deseja desativar a CRC?"
        },
        [CrcSituation.DISABLED]: {
          label: "Ativar",
          icon: "mdi-restore",
          situationModalTitle: "Você deseja ativar a CRC?"
        }
      }
    }),
    mounted() {
      this.initialize();
    },
    watch: {
      "crc.active": function() {
        this.initialize();
      }
    },
    methods: {
      ...mapActions("crc", ["toggleStatusCRC"]),
      ...mapActions(["toggleSnackbar"]),

      initialize() {
        if (this.crc.active) {
          return (this.state.mode = this.situationModes[
            CrcSituation.ACTIVATED
          ]);
        }
        this.state.mode = this.situationModes[CrcSituation.DISABLED];
      },

      async actionChangeSituation() {
        try {
          const response = await this.toggleStatusCRC(this.crc.id);
          this.$emit("success");
          this.toggleSnackbar({
            text: response?.message ?? "Registo atualizado com sucesso",
            type: "success"
          });
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        } finally {
          this.state.dialog = false;
        }
      }
    }
  };
</script>
<style></style>
