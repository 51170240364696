<template>
  <div class="d-flex justify-space-between flex-wrap align-end mb-5">
    <div class="d-flex">
      <v-icon
        v-if="$route.meta.iconBack"
        color="white"
        @click="$router.back()"
        left
        >mdi-arrow-left</v-icon
      >
      <h1>{{ headerTitle }}</h1>
    </div>
    <v-btn
      v-if="hasActionPermission"
      @click="btnAction"
      color="secondary-color-1"
      large
      height="56"
      width="256"
      class="white--text"
      >{{ btnLabel }}</v-btn
    >
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        required: false
      },
      btnLabel: {
        type: String,
        required: true
      },
      btnAction: {
        type: Function,
        required: true
      },
      hasActionPermission: {
        type: Boolean,
        required: true
      }
    },
    computed: {
      headerTitle() {
        return this.title ?? this.$route.meta.title;
      }
    }
  };
</script>
<style lang="scss" scoped>
  .v-btn {
    font-size: 16px;
    font-weight: 500;
    text-transform: none;
    border-radius: 6px;
  }
</style>
