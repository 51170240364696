import "jspdf-autotable";
import jsPDF from "jspdf";
import logo from "@/assets/Logo_PF.png";
import image from "@/assets/background.jpg";

class GenerateReports {
  constructor({ header, body, fileName }) {
    this.pdfConfig = {
      columns: header,
      body,
      theme: "striped",
      styles: {
        fillColor: [255, 255, 255]
      },
      headStyles: {
        textColor: [0, 0, 0]
      },
      bodyStyles: {
        fontSize: 9
      },
      margin: { top: 70 }
    };

    let json_fields = {};

    header.forEach(el => {
      json_fields[el.header] = el.dataKey;
    });

    this.excelConfig = {
      json_fields,
      json_data: body,
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8"
          }
        ]
      ]
    };

    this.fileName = fileName;
  }

  generatePdf() {
    const doc = new jsPDF();

    // Background
    doc.addImage(image, 0, 0, 210, 300);

    // Logo
    doc.addImage(logo, 75, 32, 55, 17);

    doc.setTextColor(234, 87, 38);
    doc.setFontSize(18);
    const margin = "Funcionários".length - this.fileName.length;
    doc.text(67 + margin, 62, `Listagem de ${this.fileName}`);

    doc.autoTable(this.pdfConfig);

    doc.save(`${this.fileName}.pdf`);
  }

  generateExcel() {
    return this.excelConfig;
  }
}

export default GenerateReports;
