<template>
  <div class="container-dropdown-user">
    <v-menu transition="slide-x-transition" bottom right>
      <template v-slot:activator="{ on, attrs }">
        <div
          class="container-report"
          :class="{ 'disabled-report': body.length <= 0 }"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon class="icon-dropdown-user" v-bind="attrs" v-on="on"
            >mdi-download</v-icon
          >
          <v-icon class="menu-options" v-bind="attrs" v-on="on"
            >mdi-menu-down</v-icon
          >
        </div>
      </template>

      <v-list>
        <v-list-item
          @click="generateReports.generatePdf()"
          class="item-dropdown-user"
        >
          <v-list-item-title>
            <v-icon class="icons-dropdown-user">mdi-file-chart</v-icon
            ><span class="label-dropdown-user">PDF</span>
          </v-list-item-title>
        </v-list-item>
        <v-list-item class="item-dropdown-user">
          <v-list-item-title>
            <json-excel
              :data="generateReports.generateExcel().json_data"
              :fields="generateReports.generateExcel().json_fields"
              :worksheet="fileName"
              :name="`${fileName}.xls`"
            >
              <v-icon class="icons-dropdown-user">mdi-file-excel</v-icon
              ><span class="label-dropdown-user">Excel </span>
            </json-excel>
          </v-list-item-title>
        </v-list-item>
        <v-list-item class="item-dropdown-user">
          <v-list-item-title>
            <json-excel
              :data="generateReports.generateExcel().json_data"
              :fields="generateReports.generateExcel().json_fields"
              type="csv"
              :name="`${fileName}.csv`"
            >
              <v-icon class="icons-dropdown-user"
                >mdi-file-delimited-outline</v-icon
              ><span class="label-dropdown-user">CSV</span>
            </json-excel>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
  import JsonExcel from "vue-json-excel";

  import GenerateReports from "@/modules/core/helpers/generateReports";

  export default {
    name: "ReportOptions",

    props: {
      fileName: {
        type: String,
        required: true,
        default: () => ""
      },
      header: {
        type: Array,
        required: true,
        default: () => []
      },
      body: {
        type: Array,
        required: true,
        default: () => []
      }
    },

    components: {
      JsonExcel
    },

    watch: {
      body() {
        this.updateReportObject();
      }
    },

    data: () => ({
      generateReports: {}
    }),

    created() {
      this.updateReportObject();
    },

    methods: {
      updateReportObject() {
        this.generateReports = new GenerateReports({
          header: this.header,
          body: this.body,
          fileName: this.fileName
        });
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/stylesheets/styleguide";
  @import "@/assets/stylesheets/components";

  @include dropdown-items();

  .container-dropdown-user {
    width: max-content;
    margin: auto;

    .container-report {
      background-color: rgb(60, 168, 151);
      padding: 15.62px 8px;
      border-radius: 4px;
      cursor: pointer;

      .menu-options {
        color: #fff;
      }

      .icon-dropdown-user {
        color: #fff;
      }
    }
  }

  .disabled-report {
    background-color: #bdbdbd !important;
    pointer-events: none !important;

    .menu-options {
      color: #8c8c8c !important;
    }

    .icon-dropdown-user {
      color: #8c8c8c !important;
    }
  }
</style>
