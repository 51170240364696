<template>
  <v-container class="content">
    <title-header-action
      :hasActionPermission="canRegisterCrc"
      btnLabel="Cadastrar CRC"
      :btnAction="goToAddCrc"
    />
    <v-card class="pt-7">
      <div class="table-page d-flex flex-column justify-space-between">
        <div>
          <template v-if="showFilters">
            <div class="row px-5 d-flex justify-end">
              <v-col cols="12" md="3">
                <v-select
                  :items="states"
                  item-text="name"
                  item-value="abbreviation"
                  outlined
                  @change="setFilter('uf', $event)"
                  color="secondary-color-1"
                  label="Todas UF"
                ></v-select>
              </v-col>
              <v-col cols="9" sm="8" md="3">
                <v-text-field
                  @input="setFilterDebounced('description', $event)"
                  solo
                  flat
                  full-width
                  color="secondary-color-1"
                  background-color="white-1"
                  placeholder="Buscar CRC"
                  prepend-inner-icon="mdi-magnify"
                ></v-text-field>
              </v-col>

              <v-col cols="3" sm="4" md="2">
                <div>
                  <report-options
                    :fileName="'Crcs'"
                    :header="headerReport"
                    :body="crcs"
                  />
                </div>
              </v-col>
            </div>
            <v-divider></v-divider>
          </template>

          <template v-if="!crcs.length">
            <div class="pt-10">
              <crc-not-found
                :title="dataNotFound"
                :canSeeAction="canRegisterCrc"
              />
            </div>
          </template>

          <template v-if="crcs.length">
            <v-data-table
              :headers="headers"
              :items="crcs"
              :items-per-page="tableInfo.itemsPerPage"
              hide-default-footer
            >
              <template v-slot:[`item.situation`]="{ item }">
                <crc-situation-chip :active="item.active"></crc-situation-chip>
              </template>
              <template v-slot:[`item.id`]="{ item }">
                <crc-menu-item
                  :crc="item"
                  :userCanUpdateSituation="userCanUpdateSituation"
                  @updateData="updateData"
                />
              </template>
            </v-data-table>
          </template>
        </div>
        <div v-if="crcs.length">
          <pagination
            :currentPage="tableInfo.currentPage"
            :totalPages="tableInfo.totalPages"
            :itemsPerPage="tableInfo.itemsPerPage"
            @currentPageChanged="handleCurrentPageChange($event)"
            @itemsPerPageChanged="handleItemsPerPageChange($event)"
          />
        </div>
      </div>
    </v-card>
  </v-container>
</template>
<script>
  import { mapGetters, mapActions } from "vuex";

  import CrcMenuItem from "@/modules/crc/views/components/CrcMenuItem";
  import CRCNotFound from "@/modules/crc/views/components/CRCNotFound";
  import CrcSituationChip from "@/modules/crc/views/components/CrcSituationChip";
  import MasksMixin from "@/modules/core/mixins/masksMixin";
  import Pagination from "@/modules/core/views/components/Pagination";
  import ReportOptions from "@/modules/core/views/components/ReportOptions";
  import TableHandler from "@/modules/core/mixins/tableHandler";
  import TitleHeaderAction from "@/modules/core/views/components/TitleHeaderAction";

  import { CrcSituation } from "@/modules/crc/enum/crcSituation.enum";
  import { profiles } from "@/modules/core/enums/profiles.enum";
  import { states } from "@/modules/core/enums/states.enum";

  export default {
    name: "ListCrcsAdmin",

    components: {
      CrcNotFound: CRCNotFound,
      ReportOptions,
      CrcSituationChip,
      CrcMenuItem,
      TitleHeaderAction,
      Pagination
    },

    mixins: [MasksMixin, TableHandler],

    data: () => ({
      headers: [
        { text: "Nome", value: "description" },
        { text: "UF", value: "address.state.uf" },
        { text: "Gerente", value: "managers" },
        { text: "Telefone", value: "phone" },
        { text: "E-mail", value: "email" },
        { text: "Situação", value: "situation" },
        { value: "id", sortable: false }
      ],

      headerReport: [
        { header: "Nome", dataKey: "description" },
        { header: "UF", dataKey: "uf" },
        { header: "Gerente", dataKey: "managers" },
        { header: "Telefone", dataKey: "phone" },
        { header: "E-mail", dataKey: "email" },
        { header: "Situação", dataKey: "situation" }
      ],

      crcs: [],

      states: []
    }),
    created() {
      this.initFilter("uf", null);
      this.initFilter("description", null);
    },
    mounted() {
      this.fetchCrcs();
      this.loadStates();
    },

    computed: {
      ...mapGetters({
        user: "user/getUser"
      }),

      showFilters() {
        if (this.crcs.length) {
          return true;
        }
        return !this.crcs.length && !this.filterPristine;
      },

      canRegisterCrc() {
        const canRegister = [profiles.PRESIDENT.id, profiles.DIRECTOR.id];

        return canRegister.includes(this.user.role.id);
      },

      userCanUpdateSituation() {
        const userCanUpdateSituation = [
          profiles.PRESIDENT.id,
          profiles.DIRECTOR.id,
          profiles.MANAGER_OPERATING.id
        ];

        return userCanUpdateSituation.includes(this.user.role.id);
      },

      dataNotFound() {
        return this.filterPristine
          ? "Você ainda não cadastrou nenhum CRC, deseja cadastrar?"
          : "CRC não encontrado.";
      }
    },
    methods: {
      ...mapActions("crc", ["getCRCs"]),
      ...mapActions(["toggleSnackbar"]),

      goToAddCrc() {
        this.$router.push({ name: "RegisterCrcs" });
      },

      async fetchCrcs() {
        try {
          const payload = this.getPayloadData();
          const { data, meta } = await this.getCRCs(payload);
          this.crcs = this.formatCrcsData(data);

          this.setTableInfo(meta);
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      },

      getNameManegers(userRoles) {
        const nameManagers = userRoles.map(userRole => {
          return `${userRole.user.firstName} ${userRole.user.surname}`;
        });

        return nameManagers.length ? nameManagers.join(", ") : "não informado";
      },

      formatCrcsData(crcs) {
        return crcs.map(crc => {
          const phone = crc.phones.length
            ? this.parsePhone(crc.phones[0])
            : "não informado";

          const managers = this.getNameManegers(crc.userRoles);

          return {
            ...crc,
            managers,
            phone,
            situation: crc.active
              ? CrcSituation.ACTIVATED
              : CrcSituation.DISABLED,
            uf: crc.address.state.uf
          };
        });
      },
      updateData() {
        this.fetchCrcs();
      },
      loadStates() {
        this.states = Object.values([
          { name: "Todos", abbreviation: null },
          ...states
        ]);
      }
    }
  };
</script>
<style lang="scss" scoped>
  @import "@/assets/stylesheets/buttons";
  @include btn-primary();
  .content {
    min-width: 350px;
  }
</style>
