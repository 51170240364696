<template>
  <v-chip text-color="white" :color="situation.color">
    {{ situation.description }}
  </v-chip>
</template>
<script>
  import { CrcSituation } from "@/modules/crc/enum/crcSituation.enum";
  export default {
    props: {
      active: {
        required: true,
        type: Boolean
      }
    },
    data: () => ({
      situationModes: {
        [CrcSituation.ACTIVATED]: {
          color: "success",
          description: "Ativado"
        },
        [CrcSituation.DISABLED]: {
          color: "black-4",
          description: "Desativado"
        }
      }
    }),
    computed: {
      situation() {
        if (this.active) {
          return this.situationModes[CrcSituation.ACTIVATED];
        }

        return this.situationModes[CrcSituation.DISABLED];
      }
    }
  };
</script>
<style lang="scss" scoped></style>
